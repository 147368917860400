<template>
    <div class="guide" @click="toHomePage">
        <div class="guideRow row align-items-center">
            <div class="col-12">
                <div class="col col-6 mx-auto col-md-2">
                    <img src="@/assets/images/logo.png" class="img-fluid mb-3 mb-md-0">
                </div>
                <h3 class="text-center p-2 font-weight-bolder d-none d-md-block">我们是深圳BOB设计</h3>
                <h3 class="text-center p-2 font-weight-bolder d-none d-md-block">专注“卖点战略+包装资产”的包装设计公司</h3>
                <h5 class="text-center p-2 d-none d-md-block" style="line-height:2;">We are Shenzhen BOB design, A packaging design company that focuses on <br>"selling point strategy+packaging assets"</h5>
                <!--phone-->
                <h4 class="text-center p-1 font-weight-bolder d-md-none">我们是深圳BOB设计</h4>
                <h4 class="text-center p-1 font-weight-bolder d-md-none">专注“卖点战略+包装资产”<br>的包装设计公司</h4>
                <h6 class="text-center p-1 d-md-none" style="line-height:1.4;">We are Shenzhen BOB design,<br>A packaging design company that focuses on<br>"selling point strategy+packaging assets"</h6>
            </div>
            <div class="footCopyRight">
                <div style="padding-bottom:2px;"><a>新礼记(深圳)品牌科技有限公司</a></div>
                <div><a class="beian" href="https://beian.miit.gov.cn/" target="_blank">备案号: {{copyRight}}</a></div>
            </div>
        </div>
    </div>
</template>
<script>
import request from '@/utils/request'

export default {
    data() {
        return {
            text: 'about',
            copyRight: '粤ICP备2023134842号-1',
            browser: "",
            browserVersion: "",
        }
    },

    computed: {

    },
    methods: {
      toHomePage() {
          this.$router.push({ path: '/work' })
      },
      updateVisitCount() {
        this.getBrowserInfo();
        // 发送数据到后端
        request({
          url: '/public/visit',
          method: 'post',
          data: {
            browser: this.browser,
            browserVersion: this.browserVersion
          }
        }).catch((error) => {
          console.error("Failed to send visit count:", error);
        });
      },
      getBrowserInfo() {
        const userAgent = navigator.userAgent;

        // 检测浏览器名称和版本
        if (userAgent.includes("Firefox")) {
          this.browser = "Firefox";
          this.browserVersion = userAgent.split("Firefox/")[1];
        } else if (userAgent.includes("Chrome")) {
          this.browser = "Chrome";
          this.browserVersion = userAgent.split("Chrome/")[1];
        } else if (userAgent.includes("Safari")) {
          this.browser = "Safari";
          this.browserVersion = userAgent.split("Version/")[1].split(" ")[0];
        } else if (userAgent.includes("Edge")) {
          this.browser = "Edge";
          this.browserVersion = userAgent.split("Edg/")[1];
        } else if (userAgent.includes("OPR")) {
          this.browser = "Opera";
          this.browserVersion = userAgent.split("OPR/")[1];
        } else if (userAgent.includes("Trident")) {
          this.browser = "Internet Explorer";
          this.browserVersion = userAgent.split("rv:")[1].split(")")[0];
        } else {
          this.browser = "Unknown";
          this.browserVersion = "Unknown";
        }
      },
    },
    watch: {

    },
    mounted() {
      //访问量统计
      this.updateVisitCount();
    }
}
</script>
<style lang="scss" scoped>
.guide {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background: url(../assets/images/guide/homeBg.jpg) repeat;
    background-size: auto;

}

.guideRow {
    height: 100%;
    color: #ffffff;
}

.footCopyRight {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 10px;
    color: #ffffff;

    .beian {
        color: #ffffff;

    }
}
</style>
