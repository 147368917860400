import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/index'
import Index from '@/views/index'
import Work from '@/views/work'
import WorkDetail from '@/views/workDetail'
import CaseDetail from '@/views/caseDetail'
import About from '@/views/about'
import News from '@/views/news'
import NewsDetail from '@/views/newsDetail'
import Contact from '@/views/contact'
import Design from '@/views/design'
import Job from '@/views/job'
Vue.use(Router)

export default new Router({
	// mode: 'history', // 去掉url中的#
	// scrollBehavior: () => ({ y: 0 }), //滚动到顶端
	scrollBehavior(to, from, saveScrollPosition) {
	    return saveScrollPosition || {y: 0};
	},
	routes: [{
			path: '/',
			component: Layout,
			redirect: '/index',
			children: [
				{
					path: '/index',
					component: Index
				},
				{
					path: '/work',
					component: Work
				},
				{
					path: '/workDetail',
					component: WorkDetail
				},
				{
					path: '/caseDetail',
					component: CaseDetail
				},
				{
					path: '/about',
					component: About
				},
				{
					path: '/news',
					component: News
				},
				{
					path: '/newsDetail',
					component: NewsDetail
				},
				{
					path: '/contact',
					component: Contact
				},
				{
					path: '/design',
					component: Design
				},
				// {
				// 	path: '/job',
				// 	component: Job
				// },
			]
		}
	]
})