<template>
    <div class="mainBox workDetail" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="container-fluid">
            <h4 class="title mt-3">{{form.title}}</h4>
            <h3 class="subTitle mt-3">{{form.subTitle}}</h3>
            <!--phone-->
            <div class="mt-3 d-md-none"><img :src="baseUrl + form.picMobile" class="w-100"></div>
            <!--PC-->
            <div class="mt-3 d-none d-md-block"><img :src="baseUrl + form.pic" class="w-100"></div>
            <div class="row opacity6">
                <div class="col-12">
                    <h5 class="mt-3">作品名称：</h5>
                    <h5 class="mt-3">{{form.name}}</h5>
                </div>
                <div class="col-12">
                    <h5 class="mt-3">品牌持有：</h5>
                    <h5 class="mt-3">{{form.brand}}</h5>
                </div>
            </div>

            <div class="desc mt-3 ql-editor" v-html="form.detail"></div>

            <div v-if="form.videos.length && form.videos[0].url" class="mt-3">
                <video style="width:100%;" controls="controls" :src="baseUrl + form.videos[0].url"></video>
            </div>
            <!--phone-->
            <div class="mt-2 d-md-none">
                <img :src="baseUrl + url" class="w-100 mt-4" v-for="url in form.mobileImages">
            </div>
            <!--PC-->
            <div class="mt-2 d-none d-md-block">
                <img :src="baseUrl + url" class="w-100 mt-4" v-for="url in form.images">
            </div>
        </div>
    </div>
</template>
<script>
import { getCase } from "@/api/design";
export default {
    data() {
        return {
            text: 'workDetail',
            baseUrl: process.env.VUE_APP_BASE_API,
            form: {
               
                brand: "",
                createBy: "",
                createTime: "",
                detail: "",
                images: [],
                mobileImages:[],
                name: "",
                bannerPic: "",
                pic: "",
                subTitle: "",
                title: "",
                updateBy: "",
                updateTime: "",
                video: "",
                videos:[]

            },
           

        }
    },
    components: {

    },
    computed: {

    },
    mounted() {
        let id = this.$route.query.id || ''
        if (id) {
            this.getDetail(id)
        }

    },
    methods: {
        getDetail(id) {
            let _this = this
            _this.loading = true
            getCase(id).then(response => {
                _this.loading = false
                let data = response.data
                data.detail = data.detail.replace(/\<img/gi, '<img style="max-width:100%;" ')
                _this.form = data;
            });
        }

    },



}
</script>
<style>
   
    .desc p{ opacity: 0.6;font-size: 1.3em;}
    .desc p:nth-of-type(-n+2) {
        opacity: 1
    }
 
</style>
<style lang="scss" scoped>
.workDetail {
    background-color: #000;
    color: #ffffff;
}

.opacity6 {
    opacity: 0.6
}

</style>