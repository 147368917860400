import request from '@/utils/request'

// 查询公益设计详细
export function getWelfareDesign() {
  return request({
    url: '/public/welfareDesign/info',
    method: 'get'
  })
}

// 查询案例列表
export function listCase(query) {
  return request({
    url: '/public/case/list',
    method: 'get',
    params: query
  })
}

// 查询案例详细
export function getCase(id) {
  return request({
    url: '/public/case/' + id,
    method: 'get'
  })
}

//新增项目申报
export function addProject(data) {
  return request({
    url: '/public/project/sub',
    method: 'post',
    data: data
  })
}

//新增设计师入驻
export function addDesigner(data) {
  return request({
    url: '/public/designer/join',
    method: 'post',
    data: data
  })
}

// 品牌设计师志愿者列表
export function listVolunteer(query) {
  return request({
    url: '/public/designerVolunteer/list',
    method: 'get',
    params: query
  })
}
