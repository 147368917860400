<template>
  <div class="container-fluid">
    <div class="mainBox">
      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center pab20"><h4>一村一品,设计助力乡村振兴</h4></div>
            <div class="text-center colorRed"><h4>公益设计行动</h4></div>
          </div>
        </div>
      </div>
      <div class="row ">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="item in form.picAttachList"><img :src="baseUrl + item" style="width:100%;"></swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
          
      

      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>PROJECT BACKGROUND<br>项目背景</h4></div>
            <div class="row justify-content-center">
              <div class="col-md-10 ">
                <div v-html="form.projectBackground && form.projectBackground.replace(/\n/g, '<br>')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>PROJECT SUBMISSION<br>项目申报</h4></div>
            <div class="row justify-content-center">
              <div class="col-md-10 ">
                
                <el-form ref="form1" :model="form1" :rules="rules1" label-width="120px" label-position="top">
                  <div class="row">
                    <div class="col-12">
                      <el-form-item label="设计内容清单" prop="detail">
                        <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 10 }" v-model="form1.detail" placeholder="" />
                      </el-form-item>
                    </div>
                    <div class="col-md-4">
                      <el-form-item label="联系电话" prop="tel">
                        <el-input v-model="form1.tel" placeholder="" />
                      </el-form-item>
                    </div>
                    <div class="col-md-4">
                      <el-form-item label="联系人职务" prop="duty">
                        <el-input v-model="form1.duty" placeholder="" />
                      </el-form-item>
                    </div>
                    <div class="col-md-4">
                      <el-form-item label="地址" prop="address">
                        <el-input v-model="form1.address" placeholder="" />
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-md-2 col-6"><el-button round style="width:100%" @click="showViewer=true">项目条件及说明</el-button></div>
                    <div class="col-md-2 col-6"><el-button type="info" round style="width:100%" @click="submitForm1">提交设计需求</el-button></div>
                  </div>
                </el-form>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>PROJECT PROCESS<br>项目流程</h4></div>
            <div class="row justify-content-center">
              <div class="col-md-10 ">
                <img src="@/assets/images/design/process.png" class="w-100" style="border:solid 1px #dcdfe6">
              </div>
            </div>
          </div>
        </div>
        
      </div>

      


      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>DESIGNER VOLUNTEER<br>品牌设计师志愿者</h4></div>
            <div class="row justify-content-center">
              <div class="col-md-10 ">
                <div class="row row-cols-2 row-cols-md-5">
                  <div class="col mb-4" v-for="item in volunteerList">
                    <div class="mb-2">
                      <img :src="baseUrl + item.pic" class="w-100">
                    </div>
                    <h4 class="mt-3">{{item.name}}</h4>
                    <h6 class="mt-2" style="white-space:pre-line;" v-html="item.content"></h6>
                  </div>
                  
                </div>
                <div class="text-center text-md-right pat10">
                  <div>'星星之火，可以燎原，乡村需要设计师，更需要用设计改变乡村的梦想家。</div>
                  <div>——致敬每一位心怀热忱的 designer</div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>



      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>DESIGNER SETTLED IN<br>设计师入驻</h4></div>
            <div class="row justify-content-center">
              <div class="col-md-10 ">
                
                <el-form ref="form2" :model="form2" :rules="rules2" label-width="120px" label-position="top">
                  <div class="row">
                    <div class="col-md-4">
                      <el-form-item label="姓名" prop="name">
                        <el-input v-model="form2.name" placeholder="" />
                      </el-form-item>
                    </div>
                    <div class="col-md-4">
                      <el-form-item label="电话" prop="tel">
                        <el-input v-model="form2.tel" placeholder="" />
                      </el-form-item>
                    </div>
                    <div class="col-md-4">
                      <el-form-item label="所在地" prop="address">
                        <el-input v-model="form2.address" placeholder="" />
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                        <el-row>
                          <el-col :span="7">
                            <el-form-item label="头像" prop="pic">
                              <el-upload accept="image/*" :action="uploadAction" list-type="picture-card" :file-list="uploadFiles_3" :auto-upload="true" :show-file-list="false" :headers="uploadHeader" :on-success="handleUploadSuccess_3">
                                <img v-if="form2.pic" :src="baseUrl + form2.pic" class="list-img" style="width:100%;height:100%; " />
                                <i v-else class="el-icon-plus"></i>
                              </el-upload>
                            </el-form-item>
                          </el-col>
                          <el-col :span="7">
                            <!-- <p class="form-tips">（提示：点击图片修改）</p> -->
                          </el-col>
                        </el-row>
                    </div>
                    <div class="col-md-6">
                      <el-form-item label="代表作" prop="attachList">
                        <el-upload :action="uploadAction" :before-upload="beforeUpload_2" :file-list="form2.attachList" :limit="6" :headers="uploadHeader" :on-success="handleUploadSuccess_2" :on-remove="handleRemove_2">
                          <el-button size="small" type="primary">点击上传</el-button>
                          <div slot="tip" class="el-upload__tip">请上传格式为<b style="color: #f56c6c">{{ fileType.join("/") }}</b>的文件</div>
                        </el-upload>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-md-2"><el-button type="info" round style="width:100%" @click="submitForm2">提交</el-button></div>
                  </div>
                </el-form>
                
              </div>
            </div>
          </div>
        </div>
        
      </div>


      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>PUBLIC WELFARE CASE<br>公益案例</h4></div>
            <div class="row justify-content-center">
              <div class="col-md-10 ">
                 <div class="row row-cols-2 row-cols-md-3">
                  <div class="col mb-4" v-for="item in caseList"  @click="toDetailPage(item)">
                    <div class="mb-2">
                      <div class="caseItem cursor">
                        <img :src="baseUrl + item.pic">
                      </div>
                    </div>
                    <h4 class="mt-3">{{item.title}}</h4>
                  </div>
                  
                </div>
              </div>
            </div>
           
          </div>
        </div>
        
      </div>
    </div>
    <el-image-viewer v-if="showViewer" :on-close="()=>{showViewer=false}" :url-list="[baseUrl + form.projectInstructions]" />
  </div>
</template>
<script>
import {getWelfareDesign,listCase,addProject,addDesigner,listVolunteer} from "@/api/design"
export default {
  components: {
      'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      text:'about',
      // 查询参数
      queryParams: {
          pageNum: 1,
          pageSize: 20

      },
      baseUrl: process.env.VUE_APP_BASE_API,
      volunteerList: [],
      //form
      form:{
        picAttachList:[], 
        projectBackground:'',
        projectInstructions:'',
      },
      showViewer: false,

      //项目
      form1:{
        detail:'',
        tel:'',
        duty:'',
        address:'',
      },
      // 表单校验
      rules1: {
        detail: [
          { required: true, message: "设计内容清单不能为空", trigger: "blur" }
        ],
        tel: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
          {
           pattern:/^(1[3-9]\d{9}|(\d{3,4}-)?\d{6,8})$/ ,
           message: '请输入正确的联系电话',
           trigger: 'blur'
          }
        ],
        duty: [
          { required: true, message: "联系人职务不能为空", trigger: "blur" }
        ],
        address: [
          { required: true, message: "地址不能为空", trigger: "blur" }
        ],
        
      },

      swiperOptions: {
        loop: true, 
        autoplay: false,
        pagination: {
          el: '.swiper-pagination'
        },
        // Some Swiper option/callback...
      },

      


      //项目
      form2:{
        name:'',
        tel:'',
        address:'',
        pic:'',
        attachList:[],
      },
      // 表单校验
      rules2: {
        name: [
          { required: true, message: "姓名不能为空", trigger: "blur" }
        ],
        tel: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
          {
           pattern:/^(1[3-9]\d{9}|(\d{3,4}-)?\d{6,8})$/ ,
           message: '请输入正确的联系电话',
           trigger: 'blur'
          }
        ],
        address: [
          { required: true, message: "所在地不能为空", trigger: "blur" }
        ],
        pic: [
          { required: true, message: "头像不能为空", trigger: "change" }
        ],
        attachList: [
          { required: true, message: "代表作不能为空", trigger: "change" }
        ],
        
        
      },

      // 上传地址
      baseUrl: process.env.VUE_APP_BASE_API,
      uploadAction: process.env.VUE_APP_BASE_API + "/public/upload", // 上传文件服务器地址
      uploadHeader: {
        Authorization: "Bearer ",
      },
      fileType:["png", "jpg", "jpeg","docx","doc", "xlsx","xls", "ppt", "txt", "pdf"],
      fileSize:10,



      // 查询参数
      queryParamsCase: {
          pageNum: 1,
          pageSize: 200

      },
      caseList:[],
      uploadFiles_3:[]




    }
  },

  computed: {
    swiper() {
        return this.$refs.mySwiper.$swiper
      }
  },
   mounted() {
      this.getWelfareDesignInfo()
      this.getVolunteerList();
      this.getCaceList()
      

  },
  methods: {
    getWelfareDesignInfo(){
      getWelfareDesign().then(response=>{
        this.form = response.data
      })
    },
    /** 查询作品列表 */
    getVolunteerList() {
        this.loading = true;
        listVolunteer(this.queryParams).then(response => {
            this.volunteerList = response.rows;
            this.loading = false;
        });
      },
    /** 查询案例列表 */
    getCaceList() {
        listCase(this.queryParamsCase).then(response => {
            this.caseList = response.rows;
        });
      },
    /** 提交按钮 */
    submitForm1() {
      this.$refs["form1"].validate(valid => {
        if (valid) {
          addProject(this.form1).then(response => {
             this.$message({
                message: '设计需求提交成功！',
                type: 'success'
              });
              this.reset1()
          });
         
        }
      });
    },
    // 表单重置
    reset1() {
      this.form1 = {
        detail:'',
        tel:'',
        duty:'',
        address:'',
      };
      this.$refs["form1"].resetFields();
    },

    beforeUpload_2(file) {
      // 校检文件类型
      if (this.fileType) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        if (!isTypeOk) {
          this.$message.error(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },
    // 视频删除处理
    handleRemove_2(file) {
      console.log('file', file)
      const newVideos = [];
      if (this.form2.attachList && this.form2.attachList.length > 0) {
        this.form2.attachList.forEach(function(item) {
          let fileName = ''
          if (file.response) {
            fileName = file.response.fileName
          } else {
            fileName = file.url
          }
          if (fileName.indexOf(item.url) == -1) {
            newVideos.push(item);
          }
        })
      }
      this.form2.attachList = newVideos;
      console.log('this.form2.attachList', this.form2.attachList)

    },

    handleUploadSuccess_2(file) {
      console.log('file', file)
      this.form2.attachList.push({ name: file.newFileName, url: file.fileName });
      console.log('this.form2.attachList', this.form2.attachList)
    },
    handleUploadSuccess_3(file) {
      this.form2.pic = file.fileName;
      this.uploadFiles_3 = [{ name: file.fileName, url: file.fileName }];
    },

    /** 提交按钮 */
    submitForm2() {
      this.$refs["form2"].validate(valid => {
        if (valid) {
          addDesigner(this.form2).then(response => {
             this.$message({
                message: '入驻申请提交成功！',
                type: 'success'
              });
              this.reset2()
          });
         
        }
      });
    },
    // 表单重置
    reset2() {
      this.form2 = {
        name:'',
        tel:'',
        address:'',
        attachList: []
      };
      this.$refs["form2"].resetFields();
    },

    toDetailPage(item) {
        this.$router.push({ path: '/caseDetail?id=' + item.id })
    },

  },
  watch: {
   
  }
}
</script>
<style>
  .jobContent>h5{
    line-height: 1.5;

  }
</style>
<style lang="scss" scoped>

  .itemWrap{
    color:#000000;
    padding:2rem 0;
    .mainTitle{
      padding-bottom:2rem;
    }
    .caseItem{
      position:relative;
      padding-top:70%;
      img{
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        z-index:2;
      }
    }
  }
</style>
