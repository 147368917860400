<template>
    <div class="mainBox work">
        <!--phone-->
        <div class="d-md-none">
            <ul class="work-list phone">
                <li  class="list-container animated slideInUp" v-for="(item,index) in worksList" :style="'grid-column-start: span ' +item.bannerColMobile +'; grid-row-start: span ' + item.bannerRowMobile" :key="item.id" @touchstart="setActiveIndex(index)" @touchend="activeIndex=null"  @click="toDetailPage(item)">
                    <div class="list-img-wrapper"><img class="list-img" :src="baseUrl + item.bannerPic"></div>
                    <div class="list-title-wrapper small" :class="[item.titlePosition,activeIndex == index ? 'hover' : '']">
                        <div class="list-title-box">
                            <div class="en">{{item.title}}</div>
                            <div class="zh">{{item.subTitle}}</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!--PC-->
        <div class="d-none d-md-block">
            <ul class="work-list  ">
                <li class="list-container animated slideInUp" v-for="item in worksList" :style="'grid-column-start: span ' +item.bannerCol +'; grid-row-start: span ' + item.bannerRow" :key="item.id" @click="toDetailPage(item)">
                    <!--v-lazy="baseUrl + item.bannerPic" -->
                    <div class="list-img-wrapper"><img class="list-img" :src="baseUrl + item.bannerPic"></div>
                    <div class="list-title-wrapper screen" :class="item.titlePosition">
                        <div class="list-title-box">
                            <div class="en">{{item.title}}</div>
                            <div class="zh">{{item.subTitle}}</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="text-center">
            <div class="spinner-border text-secondary" role="status" v-if="loading">
              <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import { listWorks, getWorks } from "@/api/works";
export default {
    components: {

    },
    data() {
        return {
            text: 'work',

            loading: false,
            total:0,
            loadingType:'more',
            activeIndex:null,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 5,
                worksTypeCode:'',

            },
            imagesList: [],
            imagesList1: [],
            //baseUrl: process.env.NODE_ENV == "development" ? '/dev-api' : "/stage-api",
            baseUrl: process.env.VUE_APP_BASE_API,
            worksList:[]
        }
    },
    
    computed: {

    },
    mounted() {
        console.log('$route.path', this.$route.path)
        window.addEventListener("scroll", this.lazyLoading)
        this.init();

    },
     watch: {
        $route(to, from) {
          // 在路由变化时执行的逻辑
          // 例如，重新获取数据
          let worksTypeCode = to.query.worksTypeCode
          this.queryParams.worksTypeCode = worksTypeCode
          this.init()
        }
    },
    methods: {
        init(){
            this.queryParams.pageNum = 1
            this.getList()
        },
        /** 查询作品列表 */
        getList() {
            this.loading = true;
            listWorks(this.queryParams).then(response => {
                this.worksList = response.rows;
                this.total = response.total;
                this.loadingType = this.worksList.length < this.total ? 'more' : 'nomore'
                this.loading = false;
            });
        },
        /** 查询作品列表 */
        getMoreList() {
            this.queryParams.pageNum = this.queryParams.pageNum+1
            this.loading = true;
            listWorks(this.queryParams).then(response => {
                this.worksList = this.worksList.concat(response.rows);
                this.total = response.total;
                this.loadingType = this.worksList.length < this.total ? 'more' : 'nomore'
                this.loading = false;
            });
        },
        lazyLoading(e) {
            //console.log(e)
            if(this.loadingType == 'nomore'){
                return;
            }
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const clientHeight = document.documentElement.clientHeight
            const scrollHeight = document.documentElement.scrollHeight
            const height = 300 //距离底部触发距离
            if (scrollTop + clientHeight >= scrollHeight - height) { //判断是否触底了
                if (!this.loading) {
                    this.getMoreList()
                }

            }

        },
        toDetailPage(item) {
            this.$router.push({ path: '/workDetail?id=' + item.id })
        },
        setActiveIndex(index){
            this.activeIndex = index
            //console.log(index)
        }
    },
    destroyed() {
        //window.removeEventListener('scroll', _.throttle(this.lazyLoading, 500))
        window.removeEventListener('scroll', this.lazyLoading)
    },
}
</script>
<style lang="scss" scoped>
.work {
    background-color: #000;
    padding-bottom:1.5rem;
}

.work-list {
    display: grid;
    grid-template-columns: repeat(3, 33.333vw);
    grid-auto-rows: 33.333vw;
    grid-auto-flow: row dense;
    place-items: stretch;
    list-style: none;
    padding: 0;
    margin: 0;

    &.phone {
        grid-template-columns: repeat(2, 50%);
        grid-auto-rows: 50vw;

    }
    .list-container {
        border: solid 1px transparent;
        cursor: pointer;
        position: relative;

        .list-img-wrapper {
            width: 100%;
            height: 100%;
            position: relative;

            .list-img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                position:absolute;
                left:50%;
                top:50%;
                transform: translate(-50%,-50%);
                // width: 100%;
                // height: 100%;
                // object-fit: cover;

            }
        }

        .list-title-wrapper {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            display: flex;
            padding: 1rem 2rem;
            opacity: 0;
            transition: all 1s;
            &.small{
                padding:1rem 1rem;
            }
            &.screen:hover{
                opacity: 1;
            }
            &.hover {
                opacity: 1;
            }

            &.leftTop {}

            &.leftBottom {
                align-items: flex-end;
            }

            &.rightBottom {
                align-items: flex-end;
                justify-content: flex-end
            }

            &.rightTop {
                justify-content: flex-end
            }

            .list-title-box {
                color: #cccccc;
                //color: #ffffff;
                // text-shadow: 2px 2px 2px black;
                .en {
                    font-size: 1rem;
                    font-weight: 200;
                }

                .zh {
                    font-size: 1.5rem;
                    font-weight: 500;
                }
            }


        }

        
    }
}
</style>