<template>
    <div class="mainBox">
        <div class="container-md mt-3 mb-5">
            <div class="text-center">
                <div class="spinner-border text-secondary" role="status" v-if="loading">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <h3 class="title text-left mainRedColor">{{form.title}}</h3>
            <h5 class="text-left mt-3 mainRedColor">{{form.publishTime}}</h5>
            <div class="des mt-3 ql-editor" >
                <div>
                    <div class="col col-md-8 offset-md-4" v-html="form.content"></div>
                </div>
            </div>

            <!--相关链接-->
            <div class="relatedBox">
                <h4 class="title">相关链接 Related links:</h4>
                <ul class="con">
                    <li @click="toWebsite(item.relatedLink)" v-for="item in form.relatedLinks">{{item.title}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import { listNews, getNews } from "@/api/news";

export default {
    components: {},
    data() {
        return {
            text: 'newsDetail',
            loading: false,
            baseUrl: process.env.VUE_APP_BASE_API,
            form:{
                title:'',
                createTime:'',
                content:'',
                relatedLinks:[]
            },



        }
    },

    computed: {

    },
    mounted() {
        let id = this.$route.query.id || ''
        if (id) {
            this.getDetail(id)
        }

    },
    watch: {
        $route(to, from) {
          // 在路由变化时执行的逻辑
          console.log('路由变化了', to, from);
          // 例如，重新获取数据
          let id = to.query.id
          this.getDetail(id)
        }
    },
    methods: {
        getDetail(id) {
            let _this = this
            _this.loading = true
            getNews(id).then(response => {
                _this.loading = false
                let data = response.data
                data.content = data.content.replace(/\<img/gi, '<img style="max-width:100%;" ')
                _this.form = data;
            });
        },
        toDetailPage(id) {
            this.$router.push({ path: '/newsDetail?id=' + id })
        },
        toWebsite(url) {
            if(url){
                window.open(url, '_blank');
            }
        },
        

    },

}
</script>
<style>
   
    .des p{ font-size: 1.3em;}

 
</style>
<style lang="scss" scoped>
.relatedBox {
    padding-top:5rem;
    color:#e60000;
    .con{
        margin-top:1rem;
    }
    li{
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
}
</style>